.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  font-size: 19px;

  &:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 5em;
    line-height: 1.5em;
    color: #000000;
    background: linear-gradient(to right, transparent, white, transparent);
  }
  &-active {
    font-weight: bold;
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    font-size: 19px;
    &:before {
      content: '';
      background: linear-gradient(to right, transparent, #818078, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      padding: 0 5em;
      line-height: 1.5em;
      color: #000000;
      background: linear-gradient(to right, transparent, white, transparent);
    }
  }
}

.no-active-competitions {
  color: silver;
  font-weight: bold;
}