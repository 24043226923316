.side-bar {
  .logo {
    a {
      img{
        max-width: 148px;
      }
    }
  }
}

.page-container {
  .logo {
    img {
      max-width: 256px;
    }
  }
}